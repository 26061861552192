<template>
  <section class="hero is-light is-fullheight-with-navbar">
		<div class="hero-body">
			<div class="container">
				<div class="columns is-centered">
					<div class="column is-size-5-desktop is-size-6-mobile-tablet is-4-desktop is-8-widescreen">
            <file-uploader></file-uploader>
					</div>
				</div>
			</div>
		</div>
		<b-footer></b-footer>
	</section>
</template>

<script>
import FileUploader from '../components/FileUploader.vue';
import BFooter from '../components/Footer.vue';

export default {
  name: "Home",
  components: {
    FileUploader,
    BFooter
  }
};
</script>
